.label {
  text-align: center;
  font-weight: 900;
  font-size: rem(220px);
  line-height: 1;
  margin-bottom: rem(48px);
  color: var(--mantine-color-gray-2);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: rem(80px);
  }
  @media (max-width: $mantine-breakpoint-md) {
    font-size: rem(120px);
  }
}

.title {
  font-size: rem(38px);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: rem(32px);
  }
}
