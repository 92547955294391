.root {
  display: flex;
  gap: var(--mantine-spacing-md);
  flex-direction: row;
  justify-content: center;
  @media (min-width: $mantine-breakpoint-sm) {
    justify-content: space-between;
  }
};

.content {
  flex: 1;
};

.sidebar {
  width: rem(200px);
  top: rem(100px);
  height: 100%;
  position: sticky;
};
