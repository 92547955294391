.viewport {
  margin: rem(-8px);
  padding: rem(8px);
};

.control {
  &[data-inactive] {
    opacity: 0;
    cursor: default;
  };
};
