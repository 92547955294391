.carouselModalHeader {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent !important;
}

.carouselModalContent {
  background-color: transparent !important;
}

.carouselModalInner {
  background-color: rgba(0, 0, 0, 0.9);
}
