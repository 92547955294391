.root {
  height: rem(320px);
  @media (min-width: $mantine-breakpoint-sm) {
    height: rem(400px);
  };
  @media (min-width: $mantine-breakpoint-md) {
    height: rem(460px);
  };
  @media (min-width: $mantine-breakpoint-lg) {
    height: rem(480px);
  };
};
