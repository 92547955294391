.root {
  display: block;
  width: 100%;
  background-color: #ddefff;
  border-radius: var(--mantine-spacing-lg);
  position: relative;
}

.caption {
  line-height: 1;
  position: absolute;
  bottom: rem(20px);
  left: rem(20px);
  text-align: left;
  font-size: rem(32px);
  font-weight: 600;
  color: var(--mantine-color-dark-4);
  @media (min-width: $mantine-breakpoint-sm) {
    font-size: rem(38px);
  }
}

.image {
  @media (min-width: $mantine-breakpoint-sm) {
    transition: transform 400ms ease;
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
}
