.root {
  display: flex;
  gap: var(--mantine-spacing-lg);
  flex-direction: column;
  @media (min-width: $mantine-breakpoint-sm) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--mantine-spacing-md);
  }
}

.rootReverse {
  flex-direction: column-reverse;
  @media (min-width: $mantine-breakpoint-sm) {
    flex-direction: row;
  }
}

.content {
  @media (min-width: $mantine-breakpoint-sm) {
    flex: 1;
  }
}

.sidebar {
  @media (min-width: $mantine-breakpoint-sm) {
    width: rem(300px);
    top: rem(100px);
    height: 100%;
    position: sticky;
  }
}
